<template>
	<v-layout fluid text-xs-center style="height: 100vh" align-center justify-center>
		<v-flex xs10 md8>
			<v-layout align-center justify-center my-3>
				<v-img v-if="logo" :src="logo" contain max-height="25vh" max-width="40vw"></v-img>
			</v-layout>
			<h1 class="display-1 text-xs-center" style="margin-top: 20px">{{ $t('password_reset.title') }}</h1>
			<v-flex xs10 offset-xs1 md8 offset-md2 mt-3>
				<v-container>
					<v-form ref="form">
						<v-flex xs12>
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<v-text-field
										v-model="password"
										:type="revealPassword1 ? 'text' : 'password'"
										:label="$t('signin.fields.new_password')"
										autofocus
										:append-icon="revealPassword1 ? 'visibility_off' : 'visibility'"
										:rules="[rules.required, rules.webdavCompliant]"
										v-on="on"
										@click:append="revealPassword1 = !revealPassword1"
									></v-text-field>
								</template>
								<span>{{ $t('signin.fields.rules') }}</span>
							</v-tooltip>
						</v-flex>
						<v-flex xs12>
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<v-text-field
										v-model="password_confirmation"
										:type="revealPassword2 ? 'text' : 'password'"
										:label="$t('signin.fields.new_password_confirmation')"
										:append-icon="revealPassword2 ? 'visibility_off' : 'visibility'"
										:rules="[rules.required, rules.webdavCompliant]"
										v-on="on"
										@click:append="revealPassword2 = !revealPassword2"
									></v-text-field>
								</template>
								<span>{{ $t('signin.fields.rules') }}</span>
							</v-tooltip>
						</v-flex>
						<v-flex xs12 sm6 offset-sm3>
							<WButton class="mt-4" @click="resetPasswordSubmit()">{{ $t('password_reset.action') }}</WButton>
							<v-layout row>
								<v-flex xs12 text-xs-center>
									<p class="pointer grey--text underline mt-2" @click="goBack()">{{ $t('actions.go_back') }}</p>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-form>
				</v-container>
			</v-flex>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import AppModuleGuard from '@/mixins/ModulesGuards/AppModuleGuard'

export default {
	name: 'PasswordResetSubmit',
	mixins: [AppModuleGuard],
	data: function () {
		return {
			password: null,
			password_confirmation: null,
			revealPassword1: false,
			revealPassword2: false,
			rules: {}
		}
	},
	computed: {
		...mapState({
			logo: state => state.whitelabel.selected.logo
		})
	},
	mounted: function () {
		window.addEventListener('keydown', this.resetWithEnter)
	},
	destroyed: function () {
		window.removeEventListener('keydown', this.resetWithEnter)
	},
	created: function () {
		this.rules = {
			required: (value = null) => {
				let result = true
				if (!value || value.trim() === '') {
					result = this.$t('signin.errors.missing')
				}
				return result
			},
			webdavCompliant: (value = '') => {
				let result = true
				if (value) {
					const forbiddenChars = this.appService.getWebDavUnSupportedChars()

					const hasUnsupportedChar = forbiddenChars.some(char => value.indexOf(char) !== -1)

					if (hasUnsupportedChar) {
						result = this.$t('signin.errors.webdavCompliant', { chars: forbiddenChars.join(', ') })
					}
				}
				return result
			}
		}
		if (this.$route.params.token) {
			this.appService.getPasswordReset(this.$route.params.token).catch(() => {
				this.goBack()
			})
		} else {
			this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('token.error.missing'))
			this.goBack()
		}
	},
	methods: {
		resetPasswordSubmit: function () {
			if (!this.password) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('signin.errors.missing_password'))
			} else if (this.password.length < 8) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('signin.errors.password_length'))
			} else if (!this.password_confirmation || this.password !== this.password_confirmation) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('signin.errors.password_confirmation'))
			} else if (!this.$refs.form.validate()) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('security.fields.rules'))
			} else {
				this.appService
					.resetPasswordSubmit(this.password, this.password_confirmation, this.$route.params.token)
					.then(() => {
						this.appEventBus.emit(this.appEvents.SNACKBAR_INFO, this.$t('password_reset.message.success'))
						this.goBack()
					})
					.catch(error => {
						this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, error.response.data.error.message)
					})
			}
		},
		goBack: function () {
			this.appService.goTo('signin')
		},
		resetWithEnter: function (e) {
			if (e.key === 'Enter') {
				this.resetPasswordSubmit()
			}
		}
	}
}
</script>
